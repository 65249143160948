"use strict";

window.chartCommentHandler = window.chartCommentHandler || {};

// on document ready
(function () {
  'use strict';

  function removeNotAcceptedCharacters(string) {
    return string.replace(/[#\x2D\.]/g, '');
  }
  window.chartCommentHandler = {
    chartType: '',
    // must be set
    // if shall save comment when the field is updated
    // this will also determine if the template should be inserted
    ignoreTemplateAndSaveOnChange: false,
    placeholder: 'Anotações sobre o gráfico com o filtro atual. Ex: Neste gráfico é possível observar que...',
    //indicates wheter there will be a list of input for annotations or only a input
    isMultiple: false,
    separator: ',',
    getCallback: undefined,
    //start init functions
    init: function init(chartType, elementSelector, isLive, ignoreTemplateAndSaveOnChange) {
      if (chartType) {
        this.chartType = chartType;
      } else {
        console.error('chartComment: chartType não foi definido');
        return;
      }
      if (ignoreTemplateAndSaveOnChange) {
        this.ignoreTemplateAndSaveOnChange = ignoreTemplateAndSaveOnChange;
      }
      this.isLive = isLive;
      if (elementSelector) {
        this.elementSelector = elementSelector;
      } else {
        this.elementSelector = '.commentchart-card';
      }
      this.initComponents();
    },
    initComponents: function initComponents() {
      var _this2 = this;
      if (!this.ignoreTemplateAndSaveOnChange) {
        this.insertCommentChartTemplate();
        this.initSaveButton();
        this.onGlobalFilterChange();
        this.bindToggleHide();
      } else {
        this.initSaveButton();
        this.initEditor();
      }
      enableTooltip();

      /** Wait 2s to get filter id properly, can't make it syncronous */
      setTimeout(function () {
        _this2.loadComment();
      }, 2000);
    },
    // insert template with handlebars
    insertCommentChartTemplate: function insertCommentChartTemplate() {
      var _this = this;
      var source = $('#commentchart-template').html();
      var template = Handlebars.compile(source);
      var isLive = this.isLive;
      var elSel = _this.elementSelector ? _this.elementSelector : '.commentchart-card';
      var html = template({
        isLive: isLive,
        elSel: removeNotAcceptedCharacters(elSel)
      });
      $(elSel).html(html);
      _this.initEditor();
    },
    instantiateCKEditor: function instantiateCKEditor(id) {
      var cardCommentId = "textcardcomment".concat(removeNotAcceptedCharacters(id));
      var config = {
        placeholder: this.placeholder
      };
      if (CKEDITOR.editorConfig) CKEDITOR.editorConfig(config);
      CKEDITOR.inline(cardCommentId);
    },
    initEditor: function initEditor() {
      var _this = this;
      if (this.isMultiple) {
        var ids = this.elementSelector.split(_this.separator);
        for (var i in ids) {
          _this.instantiateCKEditor(ids[i]);
        }
      } else {
        var elSel = _this.elementSelector ? _this.elementSelector : '.commentchart-card';
        _this.instantiateCKEditor(elSel);
      }
    },
    initSaveButton: function initSaveButton() {
      var _this = this;
      if (!this.ignoreTemplateAndSaveOnChange) {
        // != multiple
        $('.save-chart-comment-btn:not(\'.multiple\')').click(function () {
          _this.saveComment($(this));
        });
      } else {
        $('.save-chart-comment-btn.multiple').click(function () {
          _this.saveComment($(this));
        });
      }
    },
    // end init funcions

    // functionalities: save, load
    saveComment: function saveComment($clicked) {
      var _this = this;
      var closest = '.card';
      if (this.ignoreTemplateAndSaveOnChange) closest = '.group-theme-annotation-row';
      var id = $clicked.closest(closest).find('.observation-text').attr('id');
      var comment = CKEDITOR.instances[id].getData();
      var filterId = globalFilter.filter.id;
      if (!filterId) {
        createAndShowNotification('Erro ao salvar comentário.', 'error');
        return false;
      }
      if (!comment || comment.trim() === '') {
        comment = '';
      }
      if (this.ignoreTemplateAndSaveOnChange) {
        id = id.replace('textcardcomment', '');
      } else {
        id = undefined;
      }
      _this.sendAjaxToSaveChartAnnotation(comment, filterId, id);
    },
    sendAjaxToSaveChartAnnotation: function sendAjaxToSaveChartAnnotation(comment, filterId, chartType) {
      var _this = this;
      var errorFn = function errorFn() {
        createAndShowNotification('Ocorreu um erro ao salvar o comentário. Tente novamente em alguns instantes ou contate o suporte.', 'error');
      };
      if (!filterId || this.ignoreTemplateAndSaveOnChange) filterId = '';
      $.ajax({
        url: '/commentchart',
        method: 'POST',
        data: {
          comment: comment,
          filterId: filterId,
          chartType: chartType ? chartType : _this.chartType
        },
        success: function success(result) {
          if (result.status == 'OK') {
            var msg = 'Comentário salvo para o filtro em uso!';
            if (_this.ignoreTemplateAndSaveOnChange) msg = 'Anotação de grupo ou tema salva com sucesso.';
            createAndShowNotification(msg, 'success');
          } else {
            errorFn();
          }
        },
        error: function error() {
          errorFn();
        }
      });
    },
    loadComment: function loadComment(callback) {
      var _this = this;
      var errorFn = function errorFn() {
        createAndShowNotification('Ocorreu um erro ao carregar o comentário desse gráfico. Tente novamente.', 'error');
      };
      var filterId = globalFilter.filter.id;
      if (!filterId) {
        console.log('Erro ao carregar comentário.');
        return false;
      }
      if (_this.ignoreTemplateAndSaveOnChange) filterId = '';
      $.ajax({
        url: '/commentchart',
        method: 'GET',
        data: {
          filterId: filterId,
          chartType: _this.chartType,
          multiple: _this.isMultiple ? 'true' : 'false'
        },
        success: function success(result) {
          if (result.status === 'DATA') {
            if (_this.isMultiple && result.message.annotations) {
              for (var i in result.message.annotations) {
                var annObj = result.message.annotations[i];
                if (!$('#textcardcomment' + removeNotAcceptedCharacters(annObj.id)).siblings('.cke_editable').hasClass('cke_focus')) CKEDITOR.instances['textcardcomment' + removeNotAcceptedCharacters(annObj.id)].setData(annObj.annotation);
              }
              if (_this.getCallback) {
                setTimeout(function () {
                  _this.getCallback();
                }, 2000);
              }
            } else if (result.message.annotation || result.message.annotation === '') {
              CKEDITOR.instances['textcardcomment' + removeNotAcceptedCharacters(_this.elementSelector)].setData(result.message.annotation);
            }
            if (typeof callback === 'function') callback();
          } else {
            errorFn();
          }
        },
        error: function error() {
          errorFn();
        }
      });
    },
    bindToggleHide: function bindToggleHide() {
      $('.row-commentchart').unbind();
    },
    // end functionalities

    // bind event on filter change
    onGlobalFilterChange: function onGlobalFilterChange() {
      var _this = this;
      $(document).on('globalFilterChange', function () {
        setTimeout(function () {
          _this.loadComment();
        }, 400);
      });
    }
  };
})();